import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import PentagonChart from './Components/PentagonChart';
import Navbar from './Components/Navbar';

function App() {
  return (
    <Router> {/* Wrap your components with Router */}
      <div className="App">
        <Navbar /> {/* Assuming Navbar uses Router features */}
        <PentagonChart />
      </div>
    </Router>
  );
}

export default App;