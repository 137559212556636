import React, { useEffect, useRef, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-dragdata';
import { HuePicker } from 'react-color';

Chart.register(...registerables);

function PentagonChart() {
    const chartRef = useRef();
    const colorPickerRef = useRef();
    const currentIndexRef = useRef(null);
    const [hue, setHue] = useState(120);
    

    const [data, setData] = useState({
        labels: ['1', '2', '3', '4', '5'],
        datasets: [{
            label: 'Title',
            data: [100, 100, 100, 100, 100],
            fill: true,
            // Use HSL color space using the hue value directly
            backgroundColor: `hsla(${hue}, 100%, 50%, 0.2)`,
            borderColor: `hsl(${hue}, 100%, 50%)`,
            pointBackgroundColor: `hsl(${hue}, 100%, 50%)`,
            pointBorderColor: `hsl(${hue}, 100%, 50%)`,
            pointHoverBackgroundColor: `hsl(${hue}, 100%, 50%)`,
            pointHoverBorderColor: `hsl(${hue}, 100%, 50%)`,
            pointRadius: 3,
            pointHoverRadius: 5,
            pointHitRadius: 12,
        }],
    });

    const [editedLabels, setEditedLabels] = useState([...data.labels]);
    const [editedData, setEditedData] = useState([...data.datasets[0].data]);
    const [chartTitle, setChartTitle] = useState(data.datasets[0].label);
    const chartInstance = useRef(null);
    

    const handleColorPickerClick = (event) => {
        const picker = event.target;
        const rect = picker.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const hue = (x / rect.width) * 360; // Calculate hue based on click position
        setHue(hue);
        setData(prevData => ({
            ...prevData,
            datasets: [{
                ...prevData.datasets[0],
                backgroundColor: `hsla(${hue}, 100%, 50%, 0.2)`,
                borderColor: `hsl(${hue}, 100%, 50%)`,
                pointBackgroundColor: `hsl(${hue}, 100%, 50%)`,
                pointBorderColor: `hsl(${hue}, 100%, 50%)`,
                pointHoverBackgroundColor: `hsl(${hue}, 100%, 50%)`,
                pointHoverBorderColor: `hsl(${hue}, 100%, 50%)`,
            }]
        }));
    };


    const createPentagonChart = (chartData) => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const ctx = chartRef.current.getContext('2d');
        chartInstance.current = new Chart(ctx, {
            type: 'radar',
            data: chartData,
            options: {
                elements: {
                    line: { borderWidth: 3 },
                },
                scales: {
                    r: {
                        min: 0,
                        max: 100,
                        ticks: { display: false },
                        grid: {
                            color: 'grey'
                        },
                        angleLines: {
                            color: '#fff'
                        },
                        pointLabels: {
                            color: 'White', // set the color of point labels (around the perimeter)
                            font: {
                              size: 10, // set the font size of point labels
                            }
                          }
                    },
                },
                plugins: {
                    dragData: {
                        onDragStart: function(e, datasetIndex, index, value) {
                            // Store the current index
                            currentIndexRef.current = index;
                          
                            // Set the tooltip to show the label and the initial value of the data point being dragged
                            chartInstance.current.options.plugins.tooltip.callbacks.label = function(context) {
                              // Access the initial value directly from the chart's dataset
                              const initialValue = chartInstance.current.data.datasets[datasetIndex].data[index];
                              return chartInstance.current.data.labels[currentIndexRef.current] + ': ' + Math.round(initialValue);
                            };
                          
                            // Enable the tooltip when dragging starts
                            chartInstance.current.options.plugins.tooltip.enabled = true;
                            chartInstance.current.update();
                          },
                        onDrag: function(e, datasetIndex, index, value) {
                            // Update the tooltip with the current dragged value
                            chartInstance.current.options.plugins.tooltip.callbacks.label = function(context) {
                              // Use the current index from the ref
                              return chartInstance.current.data.labels[currentIndexRef.current] + ': ' + Math.round(value);
                            };
                            chartInstance.current.update('none'); // Update chart without animation
                        },
                          onDragEnd: function(e, datasetIndex, index, value) {
                            setEditedData(prevData => {
                              const newData = [...prevData];
                              newData[index] = Math.round(value); // Ensure the value is rounded and assigned to the correct index
                              return newData;
                            });
                            // Disable the tooltip when dragging ends
                            chartInstance.current.options.plugins.tooltip.enabled = false;
                            chartInstance.current.update();
                          },
                        
                        showTooltip: true,
                         magnet: {
      to: (value) => Math.max(0, value) // Prevent negative values and snap to the nearest integer
    }
                    },
                    
                    tooltip: {
                        enabled: false, // Initially disable the tooltip
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            // We don't need a title, so we'll leave this empty
                            title: function() {
                                return '';
                            },
                            label: function(context) {
                                // Default label behavior showing label and value
                                return context.label + ': ' + context.formattedValue;
                            },
                        }
                    },
                    title: {
                        display: true,
                        text: chartTitle,
                        color: `hsl(${hue}, 100%, 50%)`, // setting the title color to white
                        font: {
                            size: 20 // setting the title font size (adjust as needed)
                        }
                    },

                    legend: {
                        display: false, // This will hide the legend
                    },
                    // Other plugins can be configured here
                },
                maintainAspectRatio: false,
                aspectRatio: 1,
                  
            },
        });
    };

    useEffect(() => {
        createPentagonChart(data);
    }, [data]); // This useEffect will run whenever the 'data' state changes

    useEffect(() => {
        const handleTouchStart = (e) => {
            e.preventDefault();
        };
    
        const colorPickerElem = colorPickerRef.current;
    
        if (colorPickerElem) {
            colorPickerElem.addEventListener('touchstart', handleTouchStart, { passive: false });
        }
    
        return () => {
            if (colorPickerElem) {
                colorPickerElem.removeEventListener('touchstart', handleTouchStart);
            }
        };
    }, []);

    const handleAdd = () => {
        const newLabel = `${editedLabels.length + 1}`;
        setEditedLabels([...editedLabels, newLabel]);
        setEditedData([...editedData, 100]);
        setData({
            ...data,
            labels: [...editedLabels, newLabel],
            datasets: data.datasets.map(dataset => ({
                ...dataset,
                data: [...editedData, 100],
            })),
        });
    };

    const handleRemove = () => {
        if (editedLabels.length > 3) {
            setEditedLabels(editedLabels.slice(0, -1));
            setEditedData(editedData.slice(0, -1));
            setData({
                ...data,
                labels: editedLabels.slice(0, -1),
                datasets: data.datasets.map(dataset => ({
                    ...dataset,
                    data: editedData.slice(0, -1),
                })),
            });
        }
    };

    const handleLabelChange = () => {
        setData({
            ...data,
            labels: [...editedLabels],
            datasets: data.datasets.map(dataset => ({
                ...dataset,
                data: [...editedData],
                label: chartTitle,
            })),
        });
    };

    const handleLabelEdit = (index, newValue) => {
        const newEditedLabels = [...editedLabels];
        newEditedLabels[index] = newValue;
        setEditedLabels(newEditedLabels);
    };

    const handleDataEdit = (index, event) => {
        const value = event.target.value;
        if (/^\d*\.?\d*$/.test(value) || value === '') {
            const newEditedData = [...editedData];
            if (value !== '' && parseFloat(value) <= 100) {
                newEditedData[index] = parseFloat(value);
            } else if (value === '') {
                newEditedData[index] = value;
            } else {
                return;
            }
            setEditedData(newEditedData);
        }
    };

    return (
        <div className="pentagon-chart-container">
            <div className="pentagon-chart">
            <div className="pentagon-chart-canvas" style={{ touchAction: 'none' }}>
    <canvas ref={chartRef} />
</div>
            </div>
            
            <div className="color-picker-container" onClick={handleColorPickerClick}>
            <HuePicker
                color={`hsl(${hue}, 100%, 50%)`}
                // onChangeComplete prop removed to update color only on click
            />
        </div>
    
            <div className="pentagon-chart-inputs">
                <div className="title-and-button-container">
                    <div className="title-input-container">
                        <input
                            type="text"
                            value={chartTitle}
                            onChange={(e) => setChartTitle(e.target.value)}
                            onBlur={handleLabelChange}
                        />
                    </div>
                    <div className="update-button-container">
                        <button onClick={handleAdd}>+</button>
                        <button onClick={handleRemove}>-</button>
                    </div>
                </div>
                
                {editedLabels.map((label, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
                        <input
                            type="text"
                            value={editedLabels[index]}
                            maxlength="12"
                            onChange={event => handleLabelEdit(index, event.target.value)}
                            onBlur={handleLabelChange}
                        />
                        <input
                            type="text"
                            value={editedData[index]}
                            onChange={event => handleDataEdit(index, event)}
                            onBlur={handleLabelChange}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
    
}

export default PentagonChart;








































